<template>
    <app-drawer v-model="value" :modal="true">
        <div class="base-side-nav">
            <div v-for="(item, index) in navList" :key="index" v-power="item.code" :class="['nav__item',  {active: item.url === useRoute().path}]" @click="link(item.url)">
                <div class="item__background">
                    <img :src="item.icon" />
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
    </app-drawer>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()

const routePath = ref('')
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: Boolean): void
}>()

const value = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val)
    }
})

const link = (url: string) => {
    value.value = false
    routePath.value = url
    router.push(url)
}

const navList = ref([{
    name: '地图总览',
    icon: (await import('@/assets/nav-map.png')).default,
    url: '/map',
    code: 'SHUIWU_MAP'
},{
    name: '数据维护',
    icon: (await import('@/assets/nav-data.png')).default,
    url: '/data',
    code: 'SHUIWU_DATA'
},{
    name: '数据分析',
    icon: (await import('@/assets/nav-service.png')).default,
    url: '/analysis',
    code: 'SHUIWU_ANALYSE'
}])
</script>

<style lang="scss">
@import './index.scss';
</style>
