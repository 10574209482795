<template>
    <el-form ref="baseCode" class="base-code" label-width="auto" :model="form" :rules="rules">

        <!-- 忘记密码 -->
        <template v-if="props.forget">
            <el-form-item label="手机号码" prop="mobile">
                <el-input v-model="form.mobile" :disabled="isUpdateDefaultPwd" placeholder="请您输入手机号码" @blur="handleGetUser">
                </el-input>
            </el-form-item>
            <el-form-item label="用户" prop="userId">
                <el-select v-model="form.userId" class="select-user" :disabled="isUpdateDefaultPwd" placeholder="请选择用户">
                    <el-option v-for="item in userList" :key="item.id" :label="item.account" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </template>

        <!-- 修改个人资料 -->
        <template v-if="props.show">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请您输入姓名" />
            </el-form-item>
            <el-form-item label="电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请您输入联系电话" />
            </el-form-item>
        </template>

        <!-- 获取验证码 -->
        <template v-if="form.mobile !== userInfo.mobile || !props.show">
            <el-form-item label="图形验证码" prop="picCode">
                <el-input v-model="form.picCode" placeholder="请输入图形验证码">
                    <template #append><img :src="form.codeImage" @click="getVCode" /></template>
                </el-input>
            </el-form-item>
            <el-form-item label="短信验证码" prop="smsCode">
                <el-input v-model="form.smsCode" :placeholder="`发送到${form.mobile}`">
                    <template #append>
                        <div class="text" @click="getSendSmsCode">{{ form.time ? `${form.time} 秒后再次获取` : '获取短信验证码' }}</div>
                    </template>
                </el-input>
            </el-form-item>
        </template>
    </el-form>
    <div style="text-align: right">
        <el-button round type="primary" @click="emit('closed')">取消</el-button>
        <el-button round type="primary" @click="nextStep">{{ props.show ? '提交' : '下一步' }}</el-button>
    </div>
</template>

<script lang="ts" setup>
import { ICode, sendSmsCode, checkSmsCode, changeUserInfo, getMobileUser, sendSmsCodeNoLogin, checkSmsCodeNoLogin } from '@/api/login/index'
import { reactive } from 'vue';
import type { FormRules } from 'element-plus'
import { ElMessage } from "element-plus";

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    forget: {
        type: Boolean,
        default: false
    },
    isUpdateDefaultPwd: {
        type: Boolean,
        default: false
    },
    data: {
        type: Object,
        default: () => {
            return {}
        }
    }
})

interface EmitsType {
    (e: 'closed'): void,
    (e: 'submit', uid: Object): void
}

const emit = defineEmits<EmitsType>()

const userInfo = reactive(JSON.parse(localStorage.getItem('userInfo') || ''))

// 修改密码
const form = reactive({
    name: userInfo.name,
    mobile: props.forget ? '' : userInfo.mobile,
    userId: '',
    codeImage: '',
    picCode: '',
    smsCode: '',
    time: 0
})

const rules = reactive<FormRules>({
    name: [{ required: true, message: '请您输入姓名', trigger: 'blur' }],
    mobile: [{ required: true, message: '请您输入联系电话', trigger: 'blur' }],
    picCode: [{ required: true, message: '请输入图形验证码', trigger: 'blur' }],
    smsCode: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
    userId: [{ required: true, message: '请选择用户', trigger: 'blur' }],
})



// 获取图片验证码
const getVCode = async () => {
    form.codeImage = await ICode()
}

getVCode()

const getSendSmsCode = async () => {

    const smsCodeUrl = props.forget ? sendSmsCodeNoLogin : sendSmsCode

    smsCodeUrl({
        picCode: form.picCode,
        mobile: form.mobile,
        userId: form.userId
    }).then((res: any) => {
        if (res.status === 200) {
            ElMessage.success(res.message)
            form.time = 60
            let interval: any = null
            interval = setInterval(() => {
                form.time--
                if (form.time === 0) {
                    clearInterval(interval)
                }
            }, 1000)
        } else {
            ElMessage.error(res.message)
            getVCode()
        }
    })
}

const baseCode: any = ref(null)
const nextStep = async () => {
    await baseCode.value.validate((valid: Boolean) => {
        if (valid) {

            // 修改用户信息
            if (props.show) {
                changeUserInfo({ name: form.name, mobile: form.mobile, picCode: form.picCode, smsCode: form.smsCode, ...props.data }).then((res: any) => {
                    if (res.status === 200) {
                        userInfo.name = form.name
                        localStorage.setItem('userInfo', JSON.stringify(userInfo))
                        ElMessage.success(res.message)
                        emit('closed')
                    }
                })
            } else {

                // 修改密码/忘记密码
                const checkSmsCodeUrl = props.forget ? checkSmsCodeNoLogin : checkSmsCode
                checkSmsCodeUrl({ smsCode: form.smsCode, userId: form.userId }).then((res: any) => {
                    if (res.status === 200) {
                        emit('submit', { uid: res.data.uid, userId: form.userId })
                    }
                })
            }
        }
    })
}

const userList: any = reactive([])
// 获取手机对应的用户
const handleGetUser = () => {
    form.userId = ''
    getMobileUser({ mobile: form.mobile }).then((res: any) => {
        userList.length = 0
        userList.push(...res)
    })
}

if (props.isUpdateDefaultPwd) {
    form.mobile = props.data.mobile
    await handleGetUser()
    form.userId = props.data.userId
}
</script>

<style lang="scss">
.base-code.el-form {
    .el-form-item {
        margin-bottom: 32px !important;
    }

    .el-input-group__append {
        height: 34px;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        cursor: pointer;

        img {
            height: 100%;
            border: 1px solid #1b7ef2;
            border-left: none;
            background-color: #00152e;
        }

        .text {
            background-color: #01c2ff;
            color: #fff;
            padding: 0 10px;
        }
    }
}
</style>
