<template>
    <header class="header-nav">
        <div class="header-nav-left">
            <template v-if="props.show">
                <app-icon name="unfold" @click="showUnfold = true" />
                <div class="date">
                    <div>{{date.nowWeek}}</div>
                    <div>{{ date.nowDate }}</div>
                </div>
                <div class="time">{{date.nowTime}}</div>
            </template>
        </div>

        <div class="header-nav-center">高新区智慧水务感知平台</div>

        <div class="header-nav-right">
            <template v-if="props.show">
                <img src="@/assets/icon-head.png" />
                <div class="name">
                    欢迎你，{{ userInfo.name }} <el-icon><CaretBottom /></el-icon>
                    <div class="pull-down-box">
                        <div @click="update.showInfo = true">个人资料</div>
                        <div @click="update.showPwd = true">修改密码</div>
                        <div @click="outLogin">退出登录</div>
                    </div>
                </div>
            </template>
        </div>
    </header>

    <!-- 修改个人资料 -->
    <base-dialog v-if="update.showInfo" v-model="update.showInfo" title="修改个人资料" @closed="closeDialog">
        <div class="reset-element">
            <base-steps v-model="update.step" :list="['验证手机号码', '修改信息']" />
            <base-code v-if="update.step === 0" @closed="update.showInfo = false" @submit="nextStep" />
            <base-code v-if="update.step === 1" :data="update.data" show @closed="closeDialog" @submit="closeDialog" />
        </div>
    </base-dialog>

    <!-- 修改密码 -->
    <base-dialog v-if="update.showPwd" v-model="update.showPwd" title="修改密码" @closed="closeDialog">
        <div class="reset-element">
            <base-steps v-model="update.step" />
            <base-code v-if="update.step === 0" @closed="update.showPwd = false" @submit="nextStep" />
            <base-pwd v-if="update.step === 1" :data="update.data" @closed="closeDialog"/>
        </div>
    </base-dialog>
    
    <!-- 侧边栏 -->
    <base-side-nav v-model="showUnfold" />
</template>

<script lang="ts" setup>
import { LoginOut } from '@/api/login/index'

import BaseSideNav from '@/components/base-side-nav/index.vue'
import BaseDialog from '@/components/base-dialog/index.vue'
import BaseSteps from '@/components/base-steps/index.vue'
import BaseCode from '@/components/base-code/index.vue'
import BasePwd from '@/components/base-pwd/index.vue'

import { CaretBottom } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'

const props = defineProps({
    show: {
        type: Boolean,
        default: true
    }
})

let userInfo:any = reactive({
    name: ''
})
if(props.show && localStorage.getItem('userInfo')) {
    userInfo = reactive(JSON.parse(localStorage.getItem('userInfo') || ''))
}

// 退出登录
const showUnfold = ref(false)
const router = useRouter()
const outLogin = async () => {
    await LoginOut()
    router.push('/login')
}

// 修改密码
const update = reactive({
    showPwd: false,
    step: 0,
    data: {},
    showInfo: false
})


const nextStep = (e: Object) => {
    update.data = e
    update.step ++
}

const date = reactive({
    nowDate: '',
    nowTime: '',
    nowWeek: ''
})



const setNowTime = ()=> {
    const myDate = new Date();
    const wk = myDate.getDay();
    const yy = String(myDate.getFullYear());
    const mm = myDate.getMonth() + 1 >=10? myDate.getMonth() + 1:'0' + (myDate.getMonth() + 1);
    const dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
    const hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours());
    const min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes());
    const sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds());
    const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const week = weeks[wk];
    date.nowDate = yy + '.' + mm + '.' + dd;
    date.nowTime = hou + ':' + min + ':' + sec;
    date.nowWeek = week;
}

const closeDialog = () => {
    update.showInfo = false
    update.showPwd = false
    update.step = 0
    userInfo.name = JSON.parse(localStorage.getItem('userInfo') || '').name
}

onMounted(() => {
    setInterval(() => {
        setNowTime();
    }, 1000);
})
</script>

<style lang="scss">
.header-nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 88px;
    background: url('@/assets/header-bg.png');
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    user-select: none;

    &-center {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 4px;
        margin-top: 14px;
        color: #fff;
    }

    &-left,
    &-right {
        flex: 1;
        display: flex;
        align-items: center;
        height: 70px;
        .app-icon-unfold {
            font-size: 24px;
            cursor: pointer;
            opacity: 0.7;
            color: #29f1fa;
            transition: opacity 0.3s;
            &:hover {
                opacity: 1;
            }
        }
        .date {
            margin: 0 16px 0 10px;
            line-height: 1.5;
        }
        .time {
            font-size: 24px;
        }
    }

    &-right {
        position: relative;
        justify-content: flex-end;
        font-size: 18px;
        cursor: pointer;
        img {
            width: 40px;
        }
        .name {
            margin: 0 8px;
            line-height: 88px;
            .pull-down-box {
                position: absolute;
                top: 60px;
                right: 10px;
                background: linear-gradient(180deg, #080e1e 0%, rgba(26, 56, 56, 0.8) 99%);
                overflow: hidden;
                line-height: 1;
                height: 0;
                padding: 0;
                transition: all 0.3s;
                cursor: pointer;
                div {
                    padding: 12px 24px;
                    &:hover {
                        background-color: #01c2ff;
                        color: #fff;
                    }
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }

            &:hover {
                .pull-down-box {
                    height: 206px;
                    padding: 24px;
                }
            }
        }
    }
}

</style>
