import { RouteRecordRaw, RouterView } from 'vue-router'
import AppLayout from '../layout/app-layout.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        component: () => import('../views/login/index.vue')
    },
    {
        path: '/free-login',
        component: RouterView,
        children: [
            {
                path: '/free-login',
                component: () => import('../views/free-login/index.vue')
            }
        ]
    },
    {
        path: '/login',
        component: RouterView,
        children: [
            {
                path: '/login',
                component: () => import('../views/login/index.vue')
            }
        ]
    },
    {
        path: '',
        component: AppLayout,
        children: [
            {
                path: '/map',
                component: () => import('../views/map/index.vue')
            }
        ]
    },
    {
        path: '',
        component: AppLayout,
        children: [
            {
                path: '/data',
                component: () => import('../views/data/index.vue')
            }
        ]
    },
    {
        path: '',
        component: AppLayout,
        children: [
            {
                path: '/analysis',
                component: () => import('../views/analysis/index.vue'),
            }
        ]
    }
]

export default routes
