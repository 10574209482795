<template>
    <div ref="echarts" style="height: 100%"></div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import render from './render';
import { carouselVertical } from '../../../core/echarts-carousel';

const props = defineProps({
    /**
     * 用户配置项（继承已有配置，非必要时候勿用）
     */
    opt: {
        type: Object,
        default: () => ({}),
    },

    /**
     * 数据项
     */
    data: {
        type: Object,
        default: () => ({
            series: [
                {
                    name: '1月',
                    data: [120, 200, 150, 80, 70, 110, 130],
                },
                {
                    name: '2月',
                    data: [70, 110, 120, 200, 150, 80, 130],
                },
            ],
            axis: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }),
    },

    /**
     * 颜色
     */
    color: {
        type: Array,
        default: [],
    },

    /**
     * 柱状图宽度
     */
    barWidth: {
        type: Number,
        default: 15,
    },

    /**
     * 显示背景色
     */
    showBackground: {
        type: Boolean,
        default: false,
    },

    /**
     * 是否堆叠
     */
    stack: {
        type: Boolean,
        default: false,
    },

    /**
     * 是否曲线
     */
     smooth: {
        type: Boolean,
        default: false,
    },

    /**
     * 获取实例
     */
    instance: {
        type: Function,
        default: () => {},
    },

    /**
     * 可视化展示区
     */
    dataZoom: {
        type: Number,
        default: 0,
    },

    /**
     * 间隔
     */
    interval: {
        type: Number,
        default: null,
    },

    /**
     * 数据轮播
     */
    loop: {
        type: Number,
        default: 0,
    },
});

const echarts = ref<null>(null);

setTimeout(() => {
    watch(
        () => props.data,
        () => {
            if (echarts.value && props.data.axis && props.data.axis.length > 0) {
                const { instance, extensOpt } = render({
                    $dom: echarts,
                    $opt: props.opt,
                    $data: props.data,
                    $seriesColor: props.color,
                    $barWidth: props.barWidth,
                    $showBackground: props.showBackground,
                    $stack: props.stack,
                    $smooth: props.smooth,
                    $instance: props.instance,
                    $dataZoom: props.dataZoom,
                    $interval: props.interval,
                });

                if (props.loop) {
                    carouselVertical(instance, extensOpt, props.loop);
                }
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );
}, 1000);
</script>
