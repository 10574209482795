import request from '@/plugins/axios'

// 获取用户信息
export const Info: any = (param: object) => {
    return request.post({ url: '/admin/user/userInfo', param: param }, async (res: any) => {
        return res.data
    })
}

// 用户权限
export const Resource: any = (param: object) => {
    return request.post({ url: '/admin/user/getUserResource', param: param }, async (res: any) => {
        return res.data.resources
    })
}

/**
 * 图形验证码
 */
export const ICode: any = (param: object) => {
    return request.post({ url: '/admin/captcha', param: param }, async (res: any) => {
        return 'data:image/png;base64,' + res.data.captcha
    })
}

/**
 * 登录
 */
export const ILogin: any = (param: object) => {
    return request.post({ url: '/admin/login', param: param }, async (res: any) => {
        localStorage.setItem('admin_login_token', res.data.token)

        return res
    })
}


/**
 * 退出登录
 */
export const LoginOut: any = (param: object) => {
    return request.post({ url: '/admin/loginOut', param: param }, async (res: any) => {
        return res
    })
}

// 获取短信验证码
export const sendSmsCode: any = (param: object) => {
    return request.post({ url: '/admin/sms/sendSmsCodeWithLogin', param: param }, async (res: any) => {
        return res
    })
}

// 验证短信验证码
export const checkSmsCode: any = (param: object) => {
    return request.post({ url: '/admin/sms/checkSmsCodeWithLogin', param: param }, async (res: any) => {
        return res
    })
}

// 校验规则
export const getPwdRule: any = (param: object) => {
    return request.post({ url: '/admin/getPwdRule', param: param }, async (res: any) => {
        return res.data
    })
}

// 修改密码
export const changePwd: any = (param: object) => {
    return request.post({ url: '/admin/changePwd', param: param }, async (res: any) => {
        return res
    })
}


// 忘记密码
export const setPwd: any = (param: object) => {
    return request.post({ url: '/admin/setPwd', param: param }, async (res: any) => {
        return res
    })
}

// 修改当前登录用户信息
export const changeUserInfo: any = (param: object) => {
    return request.post({ url: '/admin/user/changeUserInfo', param: param }, async (res: any) => {
        return res
    })
}

// 根据手机号查询相同手机号的用户
export const getMobileUser: any = (param: object) => {
    return request.post({ url: '/admin/user/getMobileUser', param: param }, async (res: any) => {
        return res.data.list
    })
}

// 未登录获取短信验证码
export const sendSmsCodeNoLogin: any = (param: object) => {
    return request.post({ url: '/admin/sms/sendSmsCodeNoLogin', param: param }, async (res: any) => {
        return res
    })
}

// 未登录短信验证
export const checkSmsCodeNoLogin: any = (param: object) => {
    return request.post({ url: '/admin/sms/checkSmsCodeNoLogin', param: param }, async (res: any) => {
        return res
    })
}
