<template>
    <div class="base-steps">
        <div class="base-steps-line" :style="{width: `calc(100% - ${line.width}px)`, left: `${line.left}px`}" />
        <div v-for="(item, index) in list" :key="index" class="base-steps-item">
            <div :class="['number', { 'is-active': value === index}]">{{ index + 1 }}</div>
            <div :class="['name', { 'is-active': value === index}]">{{ item }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0
    },
    list: {
        type: Array,
        default: () => {
            return ['验证手机号码', '修改密码']
        }
    }
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: Number): void
}>()

const line:any = reactive({
    width: 0,
    left: 0
})
props.list.forEach((item:any, index) => {
    if(index === 0) {
        line.left = item.length * 7 + 22
    }
    if(index === 0 || index === props.list.length -1) {
        line.width += item.length * 7 + 22
    }
});

const value = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val)
    }
})
</script>

<style lang="scss">
.base-steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 32px 16px;

    &-line {
        position: absolute;
        top: 20px;
        border-bottom: 1px solid #01C2FF;
    }

    &-item {

        .number {
            position: relative;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            border-radius: 22px;
            background-color: #01C2FF;
            border: 4px solid rgba(255,255,255,0.5);
            font-size: 18px;
            font-weight: bold;
            margin: 0 auto;
            opacity: 0.6;
        }
        
        .name {
            margin-top: 10px;
            color: #fff;
            opacity: 0.6;
        }

        .is-active {
            opacity: 1;
        }
    }
}
</style>
