import { loadStyle, LineSimple, PieSimple, BarySimple, BaryRank, BaryRank2 } from 'qc-echarts' // v3.0.0

import style from './qc-echarts/echarts-style'

export default (app: any) => {
    loadStyle(style)

    app.use(LineSimple)
    app.use(PieSimple)
    app.use(BarySimple)
    app.use(BaryRank)
    app.use(BaryRank2)
}
