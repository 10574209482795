<template>
    <el-drawer
        v-model="value"
        append-to-body
        class="app-drawer"
        :close-on-click-modal="props.wrapperClosable"
        :direction="props.direction"
        :modal="props.modal"
        :size="props.size"
        :with-header="false"
        @close="emit('closed')">
        <div class="drawer__header">
            <div class="header__title">
                <img v-if="$slots.title" src="@/assets/icon-title.png" />
                <slot name="title"></slot>
            </div>
        </div>

        <div class="drawer__main">
            <slot />
        </div>

        <div v-if="$slots.footer" class="drawer__footer">
            <slot name="footer"></slot>
        </div>
    </el-drawer>
</template>

<script lang="ts" setup>
interface EmitsType {
    (e: 'update:modelValue', value: Boolean): void
    (e: 'closed'): void
}

const emit = defineEmits<EmitsType>()

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    direction: {
        type: String,
        default: 'ltr'
    },
    size: {
        type: String,
        default: 'auto'
    },
    modal: {
        type: Boolean,
        default: true
    },
    wrapperClosable: {
        type: Boolean,
        default: true
    }
})

const value = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val)
    }
})
</script>

<style lang="scss">
@import './index.scss';
</style>
