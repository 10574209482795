import _echarts from '../../../core/echarts-register';
import { extens } from '../../../core/echarts-extens';
import { useStyle } from '../../../core/echarts-style';

export default ({ $dom, $opt, $data, $seriesColor, $center, $radius, $legend }: any) => {
    const { $color, $tooltip } = useStyle();

    const legend: any = {
        show: true,
        orient: $legend === 'right' ? 'vertical' : 'horizontal',
        right:  $legend === 'right' ? '80' : 'auto',
        top:  $legend === 'right' ? 'middle' : 'auto',
        left:  $legend === 'right' ? 'auto' : '20',
        button:  $legend === 'right' ? 'auto' : 'middle',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
            color: 'rgba(255, 255, 255, 0.64)',
            fontSize: 14,
            lineHeight: 14,
            padding: 5,
        },
    };

    const series: any = [
        {
            name: 'Access From',
            type: 'pie',
            center: $center,
            radius: $radius,
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center',
                color: '#fff',
            },
            labelLine: {
                show: false,
            },
            data: $data,
        },
    ];

    /**
     * 导出配置项
     */
    const options = {
        color: $seriesColor || $color.theme,
        legend: legend,
        tooltip: Object.assign(
            {
                trigger: 'item',
            },
            $tooltip
        ),
        series: series,
    };

    /**
     * 继承配置项后渲染图表
     */
    _echarts.render($dom, extens($opt, options));
};
