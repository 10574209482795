<template>
    <el-form ref="basePwd" class="base-pwd" label-width="auto" :model="form" :rules="rules">
        <el-form-item label="新密码" prop="newPwd">
            <el-input v-model="form.newPwd" placeholder="请输入新密码" show-password type="password" />
        </el-form-item>
        <el-form-item label="确认新密码" prop="affirmPassword">
            <el-input v-model="form.affirmPassword" placeholder="请确认新密码" show-password type="password" />
        </el-form-item>
    </el-form>
    <div style="text-align: right">
        <el-button round type="primary" @click="emit('closed')">取消</el-button>
        <el-button round type="primary" @click="nextStep">提交</el-button>
    </div>
</template>

<script lang="ts" setup>
import { getPwdRule, changePwd, setPwd, LoginOut } from '@/api/login/index'
import { Md5 } from 'ts-md5'
import { useRouter } from 'vue-router'

import { reactive } from 'vue'
import type { FormRules } from 'element-plus'
import { ElMessage } from 'element-plus'

interface EmitsType {
    (e: 'closed'): void,
}
const emit = defineEmits<EmitsType>()

const props = defineProps({
    data: {
        type: Object,
        default: () => {
            return {}
        }
    },
    isLogin: {
        type: Boolean,
        default: true
    }
})

// 修改密码
const form = reactive({
    newPwd: '',
    affirmPassword: ''
})

// 校验规则
const pwdRule = await getPwdRule()
const validator1 = (rule: any, value: any, callback: any) => {
    if (value === '') {
        callback(new Error('请您输入新密码！'))
    } else if (pwdRule.rule.PWD_LIMIT_LEN && String(value).length < Number(pwdRule.rule.PWD_LIMIT_LEN)) {
        callback(new Error(pwdRule.ruleStr))
    } else if (pwdRule.rule.PWD_LIMIT_NEED_BIG && !value.match(/^.*[A-Z]+.*$/)) {
        callback(new Error(pwdRule.ruleStr))
    } else if (pwdRule.rule.PWD_LIMIT_NEED_SMALL && !value.match(/^.*[a-z]+.*$/)) {
        callback(new Error(pwdRule.ruleStr))
    } else if (pwdRule.rule.PWD_LIMIT_NEED_DIGIT && !value.match(/^.*[0-9]+.*$/)) {
        callback(new Error(pwdRule.ruleStr))
    } else if (pwdRule.rule.PWD_LIMIT_NEED_SPECI && !value.match(/^.*[^0-9A-Za-z]+.*$/)) {
        callback(new Error(pwdRule.ruleStr))
    } else {
        callback()
    }
}

const validator2 = (rule: any, value: any, callback: any) => {
    if (value === '') {
        callback(new Error('请您确认新密码！'))
    } else if (value !== form.newPwd) {
        callback(new Error('两次输入密码不一致！'))
    } else {
        callback()
    }
}

const rules = reactive<FormRules>({
    newPwd: [{ validator: validator1, trigger: 'blur' }],
    affirmPassword: [{ validator: validator2, trigger: 'blur' }]
})

const router = useRouter()
const basePwd: any = ref(null)
const nextStep = async () => {

    const pwdUrl = props.isLogin ? changePwd : setPwd
    await basePwd.value.validate((valid: Boolean) => {
        if (valid) {
            pwdUrl({
                newPwd: Md5.hashStr(form.newPwd),
                pwd: Md5.hashStr(form.newPwd),
                ...props.data
            }).then((res: any) => {
                if (res.status === 200) {
                    if(props.isLogin) {
                        ElMessage.success('修改成功，请重新登录')
                        LoginOut().then(() => {
                            router.push('/login')
                        })
                    } else {
                        ElMessage.success(res.message)
                        emit('closed')
                    }
                }
            })
        }
    })
}
</script>

<style lang="scss">
.base-pwd.el-form {
    .el-form-item {
        margin-bottom: 32px !important;
    }
}
</style>
