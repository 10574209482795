import { Directive } from "vue";


// 按钮权限控制指令
export const power: Directive<HTMLElement> = {
    mounted(el, bingding) {
        const { value } = bingding

        const resource = localStorage.getItem('Resource') || ''
        if (value && resource) {
            let hasPermission = false
            if(typeof(value) === 'string') {
                 hasPermission = resource.indexOf(value) > -1
            } else {
                value.forEach((item:any) => {
                    if(resource.indexOf(item) > -1) {
                        hasPermission = true
                    }
                });
            }
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error('v-power has error!')
        }
    }
}
