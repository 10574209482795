import _echarts from '../../../core/echarts-register';
import { extens } from '../../../core/echarts-extens';
import { useStyle } from '../../../core/echarts-style';
import { tooltipFormatter } from '../../../core/echarts-tooltip';

export default ({ $dom, $opt, $data, $seriesColor, $smooth, $areaGradient }: any) => {
    const { $color, $grid, $tooltip, $xAxis, $yAxis, $legend, $title } = useStyle();

    const series: any = [];

    $data.series.forEach((item: any, index: number) => {
        const color: any = $seriesColor[index] || $color.theme[index];

        series.push({
            type: 'line',
            name: item.name,
            data: item.data,
            smooth: $smooth,
            itemStyle: {
                color: color,
            },

            ...($areaGradient
                ? {
                      areaStyle: {
                          show: false,
                          color: {
                              type: 'linear',
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1,
                              colorStops: [
                                  {
                                      offset: 0,
                                      color: color,
                                  },
                                  {
                                      offset: 1,
                                      color: color.replace(/(\d+)(\))/g, `${0}$2`), // 必须使用 rgba 格式，否则这里会报错
                                  },
                              ],
                          },
                      },
                  }
                : {}),
        });
    });

    /**
     * 导出配置项
     */
    const options = {
        grid: $grid,
        title: $title,
        tooltip: Object.assign(
            {
                trigger: 'axis',
                formatter: tooltipFormatter($data),
            },
            $tooltip
        ),
        legend: Object.assign({}, $legend),
        xAxis: Object.assign({ data: $data.axis }, $xAxis.vertical),
        yAxis: [
            { ...$yAxis.vertical },
            {
                ...$yAxis.vertical,
                axisLabel: {
                    ...$yAxis.vertical.axisLabel,
                    formatter: (label: string) => {
                        return label + '%';
                    },
                },
            },
        ],
        series: series,
    };

    /**
     * 继承配置项后渲染图表
     */
    const extensOpt = extens($opt, options);
    const echarts = _echarts.render($dom, extensOpt);

    return { instance: echarts, extensOpt: extensOpt };
};
