<template>
    <svg aria-hidden="true" :class="`app-icon app-icon-${name}`">
        <use :xlink:href="symbolId" />
    </svg>
</template>

<script lang="ts" setup>

const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const symbolId = computed(() => `#app-icon-${props.name}`)
   
</script>
   
  <style lang="scss" scoped>
  .app-icon {
    width: 1em;
    height: 1em;
    /* 继承父元素颜色 */
    fill: currentColor;
    overflow: hidden;
  }

  </style>