import { init } from 'echarts';

class Echarts {
    loaded: any[];

    constructor() {
        this.loaded = [];

        window.addEventListener('resize', () => {
            this.resize();
        });
    }

    /**
     * 渲染图表
     */
    render(dom: any, opt: any) {
        if (dom.value) {
            const instance = init(dom.value);

            instance.clear();

            opt.animation = true;

            instance.setOption(opt);

            this.loaded.push(instance);

            return instance;
        }
    }

    /**
     * 图表重载
     */
    resize() {
        this.loaded.forEach((instance: any) => {
            setTimeout(() => {
                instance.resize();
            }, 1000);
        });
    }

    /**
     * 销毁图表
     */
    destroy() {
        while (this.loaded.length) {
            const instance = this.loaded.pop();

            setTimeout(() => {
                instance.dispose();
            }, 1000);
        }
    }
}

const _echarts = new Echarts();

export default _echarts;
