import _echarts from '../../../core/echarts-register';
import { extens } from '../../../core/echarts-extens';
import { useStyle } from '../../../core/echarts-style';
import { tooltipFormatter } from '../../../core/echarts-tooltip';

export default ({ $dom, $opt, $data, $seriesColor, $areaGradient, $barWidth }: any) => {
    const { $color, $grid, $tooltip, $xAxis, $yAxis, $legend, $title } = useStyle();

    const series: any = [];

    $data.series.forEach((item: any, index: number) => {
        let itemStyle = {
            color: $seriesColor[index] || $color[index],
        };

        if ($seriesColor && typeof $seriesColor[0] === 'object') {
            itemStyle.color = {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: $seriesColor[index][0],
                    },
                    {
                        offset: 1,
                        color: $seriesColor[index][1],
                    },
                ],
            };
        }

        if (item.type !== 'line') {
            series.push({
                type: 'bar',
                name: item.name,
                data: item.data,
                barWidth: $barWidth,
                itemStyle,
                yAxisIndex: 0,
            });
        }
    });

    /**
     * 导出配置项
     */
    const options = {
        grid: $grid,
        title: $title,
        tooltip: Object.assign(
            {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: tooltipFormatter($data),
            },
            $tooltip
        ),
        legend: Object.assign({}, $legend),
        xAxis: {
            ...$xAxis.horizontal,
        },
        yAxis: [
            {
                ...$yAxis.horizontal,
                data: $data.axis,
            },
        ],
        series: series,
    };

    /**
     * 继承配置项后渲染图表
     */
    const extensOpt = extens($opt, options);
    const echarts = _echarts.render($dom, extensOpt);

    return { instance: echarts, extensOpt: extensOpt };
};
