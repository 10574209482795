import _echarts from '../../../core/echarts-register';
import { extens } from '../../../core/echarts-extens';
import { useStyle } from '../../../core/echarts-style';
import { tooltipFormatter } from '../../../core/echarts-tooltip';

export default ({ $dom, $opt, $data, $seriesColor, $barWidth, $showBackground, $stack, $smooth, $instance, $dataZoom, $interval }: any) => {
    const { $color, $grid, $tooltip, $xAxis, $yAxis, $legend, $title } = useStyle();

    const series: any = [];

    $data.series?.forEach((item: any, index: number) => {
        let itemStyle = {
            color: $seriesColor[index] || $color[index],
        };

        if ($seriesColor && typeof $seriesColor[0] === 'object') {
            itemStyle.color = {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: $seriesColor[index][0],
                    },
                    {
                        offset: 1,
                        color: $seriesColor[index][1],
                    },
                ],
            };
        }

        if (item.type === 'line') {
            series.push({
                type: 'line',
                name: item.name,
                data: item.data,
                itemStyle,
                yAxisIndex: 1,
                smooth: $smooth
            });
        }

        if (item.type !== 'line') {
            series.push({
                type: 'bar',
                name: item.name,
                data: item.data,
                barWidth: $barWidth,
                itemStyle,
                yAxisIndex: 0,
                showBackground: $showBackground,
                stack: $stack,
            });
        }
    });

    /**
     * 导出配置项
     */
    const options = {
        grid: $grid,
        title: $title,
        tooltip: Object.assign(
            {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: tooltipFormatter($data),
            },
            $tooltip
        ),

        dataZoom: $dataZoom
            ? [
                  {
                      type: 'inside',
                      startValue: $data.axis[0],
                      endValue: $data.axis[$dataZoom - 1],
                  },
                  {
                      startValue: $data.axis[0],
                      endValue: $data.axis[$dataZoom - 1],
                  },
              ]
            : null,
        legend: Object.assign({}, $legend),
        xAxis: {
            ...$xAxis.vertical,
            data: $data.axis,
            axisLabel: {
                ...$xAxis.vertical.axisLabel,
                interval: $interval,
            },
        },
        yAxis: [
            {
                ...$yAxis.vertical,
            },
            {
                ...$yAxis.vertical,
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    ...$yAxis.vertical.axisLabel,
                    formatter: (label: string) => {
                        return label
                    },
                },
            },
        ],
        series: series,
    };

    /**
     * 继承配置项后渲染图表
     */
    const extensOpt = extens($opt, options);
    const echarts = _echarts.render($dom, extensOpt);

    $instance(echarts);

    return { instance: echarts, extensOpt: extensOpt };
};
