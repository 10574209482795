import axios from 'axios'
import qs from 'qs'
import { ElMessage } from 'element-plus'

/**
 * 请求拦截器
 */
axios.interceptors.request.use((config: any) => {
    console.log(config)
    return config
})

const showMsg = ref(true)
/**
 * 响应拦截器
 */
axios.interceptors.response.use((response: any) => {
    if (response.data.status === -400 && showMsg.value) {
        showMsg.value = false
        ElMessage.error(response.data.message)
        const useRouter = () => import('@/router')
        useRouter().then((router) => {
            console.log(router)

            router.default.push('/login')
        })
    }
    return response
})

/**
 * 接口请求方法
 */
const request = (method: string, option: any, fn: Function = (x: any) => x) => {
    return new Promise((resolve) => {
        if (option.url) {
            option.param = option.param || {}

            const token = localStorage.getItem('admin_login_token')

            option.param.admin_login_token = token

            const param = qs.stringify(option.param, { arrayFormat: 'repeat' })


            axios({
                method: method,
                url: option.url,
                params: method === 'get' ? param : {},
                data: method === 'post' ? param : {},
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;'
                },
                withCredentials: false, // 表示跨域请求时是否需要使用凭证
                timeout: 5000
            }).then((res: any) => {
                if (res.data.status !== 200 && showMsg.value) {
                    if (showMsg.value) {
                        showMsg.value = false
                        ElMessage.error(res.data.message)
                    }
                } else {
                    resolve(fn(res.data))
                }
            })
        } else {
            resolve(fn(null))
        }
    })
}

export default {
    get: (option: any, fn: Function = (x: any) => x) => request('get', option, fn),
    post: (option: any, fn: Function = (x: any) => x) => request('post', option, fn),
    put: (option: any, fn: Function = (x: any) => x) => request('put', option, fn)
}
