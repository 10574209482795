<template>
    <el-dialog v-model="value" class="base-dialog" width="486" :close-on-click-modal="false">
        <div class="base-dialog-content">
            <div class="base-dialog-title"><img src="@/assets/icon-title.png" />{{ title }}</div>
            <slot />

            <div class="base-dialog-footer">
                <slot name="footer">
                
                </slot>
            </div>
        </div>
    </el-dialog>
</template>

<script lang="ts" setup>
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: ''
    }
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: Boolean): void,
    (e: 'closed'): void
}>()

const value = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val)
    }
})
</script>

<style lang="scss">
.base-dialog {
    background: transparent;

    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        position: relative;
        background: url('@/assets/dialog-bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 28px 32px;
        color: #a8d6ff;
    }

    &-title {
        color: #01c2ff;
        font-weight: bold;
        font-size: 18px;
        img {
            width: 24px;
            margin-right: 4px;
            vertical-align: top;
        }
    }

    &-content {
        height: 100%;
        position: relative;
    }

    &-footer {
        text-align: right;
    }
}
</style>
