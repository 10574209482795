import { cloneDeep } from 'lodash-es';

/**
 * 数据轮播（纵向）
 * 采用滑动窗口双指针算法
 */
const carouselVertical = (instance: any, extensOpt: any, loop: number) => {
    if (instance) {
        const opt = _.cloneDeep(extensOpt);

        let point1 = 0;
        let point2 = loop;
        let timer = 0;

        const fn = () => {
            new Promise((resolve: any) => {
                if (!instance._dom || extensOpt.xAxis.data.length <= loop) return clearInterval(timer);

                if (point2 <= extensOpt.xAxis.data.length) {
                    opt.xAxis.data = extensOpt.xAxis.data.slice(point1, point2);

                    opt.series.forEach((x: any, i: number) => {
                        x.data = extensOpt.series[i].data.slice(point1, point2);
                    });

                    instance.setOption(opt);
                    point1++;
                    point2++;
                } else {
                    point1 = 0;
                    point2 = loop;
                }
                resolve();
            });
        };

        fn();
        // timer = setInterval(async () => {
        //     await fn();
        // }, 3000);

        // instance._dom.addEventListener('mouseover', () => {
        //     clearInterval(timer);
        // });
        // instance._dom.addEventListener('mouseout', async () => {
        //     fn();
        //     timer = setInterval(async () => {
        //         await fn();
        //     }, 3000);
        // });
    }
};

/**
 * 数据轮播（横向）
 * 采用滑动窗口双指针算法
 */
const carouselHorizontal = (instance: any, extensOpt: any, loop: number) => {
    if (instance) {
        const opt = _.cloneDeep(extensOpt);

        let point1 = 0;
        let point2 = loop;
        let timer = 0;

        const fn = () => {
            if (!instance._dom || extensOpt.yAxis[0].data.length <= loop) return clearInterval(timer);

            if (point2 <= extensOpt.yAxis[0].data.length) {
                opt.yAxis[0].data = extensOpt.yAxis[0].data.slice(point1, point2);

                opt.series.forEach((x: any, i: number) => {
                    x.data = extensOpt.series[i].data.slice(point1, point2);
                });

                instance.setOption(opt);

                point1++;
                point2++;
            } else {
                point1 = 0;
                point2 = loop;
            }
        };

        fn();
        // timer = setInterval(() => {
        //     fn();
        // }, 3000);
    }

};

export { carouselVertical, carouselHorizontal };
