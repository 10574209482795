/**
 * 提示框内容格式化
 */
const tooltipFormatter = ($data: any) => {
    return (params: any) => {
        let res = `${params[0].name}`;
        params.forEach((x: any) => {
            const unit = `（${$data.series[x.seriesIndex].unit}）`;
            res += `<br /> ${x.marker} ${x.seriesName} ${$data.series[x.seriesIndex].unit ? unit : ''} ${x.value}`;
        });

        return res;
    };
};

export { tooltipFormatter };
