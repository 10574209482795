import { power } from './utils/directive' // 自定义指令
import dom from './app.vue'
import Router from './router'
import { createApp } from 'vue'
// import Store from './store'
import registerEcharts from './plugins/echarts'
import registerQcEcharts from './plugins/qc-echarts'
import 'virtual:svg-icons-register'

/**
 * 引用全局 css
 */
import './styles/reset.scss'
import './styles/common.scss'
import './styles/reset-element.scss'


/**
 * 全局组件
 */
const app = createApp(dom)

app.directive('power', power);

registerEcharts(app)
registerQcEcharts(app)

/**
 * 挂载应用
 */
app.use(Router)
// app.use(Store)
app.mount('#app')
