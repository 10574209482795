<template>
    <div ref="echarts" class="qc-echarts"></div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import render from './render';
import { carouselVertical } from '../../../core/echarts-carousel';

const props = defineProps({
    /**
     * 用户配置项（继承已有配置，非必要时候勿用）
     */
    opt: {
        type: Object,
        default: () => ({}),
    },

    /**
     * 数据项
     */
    data: {
        type: Object,
        default: () => ({
            series: [
                {
                    name: '1月',
                    data: [120, 200, 150, 80, 70, 110, 130],
                },
            ],
            axis: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        }),
    },

    /**
     * 颜色
     */
    color: {
        type: Array,
        default: [],
    },

    /**
     * 曲线
     */
    smooth: {
        type: Boolean,
        default: false,
    },

    /**
     * 区域渐变
     */
    areaGradient: {
        type: Boolean,
        default: false,
    },
});

const echarts = ref<null>(null);

setTimeout(() => {
    watch(
        () => props.data,
        () => {
            if (props.data.axis && props.data.axis.length > 0) {
                const { instance, extensOpt } = render({
                    $dom: echarts,
                    $opt: props.opt,
                    $data: props.data,
                    $seriesColor: props.color,
                    $smooth: props.smooth,
                    $areaGradient: props.areaGradient,
                });

                if (props.loop) {
                    carouselVertical(instance, extensOpt, props.loop);
                }
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );
}, 1000);
</script>

<style lang="scss">
@import '../../../styles/index.scss';
</style>
